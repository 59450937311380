import { is } from 'effector';
export function splitMap(_ref) {
  var {
    source,
    cases
  } = _ref;
  var result = {};
  var current = is.store(source, {
    name: "current",
    sid: "-htjkz7"
  }) ? source.updates : source;

  for (var key in cases) {
    if (key in cases) {
      (function () {
        var _fn = cases[key];
        result[key] = current.filterMap(_fn);
        current = current.filter({
          fn: data => !_fn(data)
        });
      })();
    }
  } // eslint-disable-next-line no-underscore-dangle


  result.__ = current;
  return result;
}