import { createEffect, createEvent, forward, is, sample, combine } from 'effector';
export function delay(_ref) {
  var {
    source,
    timeout,
    target = createEvent({
      name: "target",
      sid: "-z2vw3w"
    }, {
      name: "target",
      sid: "-z2vw3w"
    })
  } = _ref;
  if (!is.unit(source)) throw new TypeError('source must be a unit from effector');
  if (!is.unit(target)) throw new TypeError('target must be a unit from effector');
  var ms = validateTimeout(timeout);
  var timerFx = createEffect(_ref2 => {
    var {
      payload,
      milliseconds
    } = _ref2;
    return new Promise(resolve => {
      setTimeout(resolve, milliseconds, payload);
    });
  }, {
    name: "timerFx",
    sid: "-584o7j"
  });
  sample({
    and: [{
      // ms can be Store<number> | number
      // converts object of stores or object of values to store
      source: combine({
        and: [{
          milliseconds: ms
        }],
        or: {
          name: "source",
          sid: "9rbdjo"
        }
      }),
      clock: source,
      fn: (_ref3, payload) => {
        var {
          milliseconds
        } = _ref3;
        return {
          payload,
          milliseconds: typeof milliseconds === 'function' ? milliseconds(payload) : milliseconds
        };
      },
      target: timerFx
    }],
    or: {
      sid: "-wmj29s"
    }
  });
  forward({
    and: {
      from: timerFx.doneData,
      to: target
    },
    or: {
      sid: "-vsadmx"
    }
  });
  return target;
}

function validateTimeout(timeout) {
  if (is.store(timeout, {
    sid: "-va560a"
  }) || typeof timeout === 'function' || typeof timeout === 'number') {
    return timeout;
  }

  throw new TypeError("'timeout' argument must be a function, Store, or a number. Passed \"".concat(typeof timeout, "\""));
}