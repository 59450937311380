import { combine, is } from 'effector';
var strategies = {
  some: list => list.some(Boolean),
  every: list => list.every(Boolean)
};
export function pending(_ref) {
  var {
    effects: rawEffects,
    domain,
    of = 'some'
  } = _ref;
  if (!is.domain(domain, {
    sid: "97b92c"
  }) && !rawEffects) throw new TypeError('domain or effects should be passed');
  if (of !== 'some' && of !== 'every') throw new TypeError("strategy parameter \"of\" can be \"every\" or \"some\". Passed: \"".concat(of, "\""));
  var effects = rawEffects !== null && rawEffects !== void 0 ? rawEffects : [];
  var strategy = strategies[of];

  if (domain) {
    effects = [];
    domain.onCreateEffect(fx => effects.push(fx));
  }

  return combine({
    and: [effects.map(fx => fx.pending), strategy],
    or: {
      sid: "a37bj0"
    }
  });
}