import { createStore } from 'effector';
export function status(_ref) {
  var {
    effect,
    defaultValue = 'initial'
  } = _ref;
  var $status = createStore(defaultValue, {
    name: "$status",
    sid: "abrgim"
  });
  $status.on(effect, () => 'pending').on(effect.done, () => 'done').on(effect.fail, () => 'fail');
  return $status;
}