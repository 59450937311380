function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { createEvent, createStore, guard, is, merge, sample, withRegion } from 'effector';
export function combineEvents(_ref) {
  var {
    events,
    reset,
    target = createEvent({
      name: "target",
      sid: "3pez5"
    }, {
      name: "target",
      sid: "3pez5"
    })
  } = _ref;
  if (!is.unit(target)) throwError('target should be a unit');
  if (reset && !is.unit(reset)) throwError('reset should be a unit');
  withRegion(target, () => {
    var keys = Object.keys(events);
    var defaultShape = Array.isArray(events) ? [...keys].fill('') : {};
    var $counter = createStore(keys.length, {
      name: "$counter",
      sid: "-dszn9a"
    });
    var $results = createStore(defaultShape, {
      name: "$results",
      sid: "boz3x7"
    });
    $counter.reset(sample({
      and: [target],
      or: {
        sid: "-8yzcn6"
      }
    }));
    $results.reset(target);

    if (reset) {
      $counter.reset(sample({
        and: [reset],
        or: {
          sid: "-72suil"
        }
      }));
      $results.reset(reset);
    }

    var _loop = function _loop(key) {
      var $isDone = createStore(false, {
        name: "$isDone",
        sid: "ucs7nc"
      }).on(events[key], () => true).reset(target);

      if (reset) {
        $isDone.reset(reset);
      }

      $counter.on($isDone, value => value - 1);
      $results.on(events[key], (shape, payload) => {
        var newShape = Array.isArray(shape) ? [...shape] : _objectSpread({}, shape);
        newShape[key] = payload;
        return newShape;
      });
    };

    for (var key of keys) {
      _loop(key);
    }

    guard({
      and: [{
        source: sample({
          and: [$results, merge(Object.values(events), {
            name: "and",
            sid: "-l8g4ey"
          })],
          or: {
            name: "source",
            sid: "uf92v9"
          }
        }),
        filter: $counter.map(value => value === 0),
        target
      }],
      or: {
        sid: "ciekm0"
      }
    });
  });
  return target;
}

function throwError(message) {
  throw new Error(message);
}