import { createEvent, createEffect, createStore, guard, sample, attach, is } from 'effector';
export function interval(_ref) {
  var {
    timeout,
    start,
    stop,
    leading = false,
    trailing = false
  } = _ref;
  var tick = createEvent({
    name: "tick",
    sid: "uo9p3s"
  });
  var $isRunning = createStore(false, {
    name: "$isRunning",
    sid: "-wxesi8"
  });
  var $timeout = toStoreNumber(timeout);
  var $notRunning = $isRunning.map(running => !running);
  var saveTimeout = createEvent({
    name: "saveTimeout",
    sid: "as5clk"
  });
  var $timeoutId = createStore(null, {
    name: "$timeoutId",
    sid: "-bh90bo"
  }).on(saveTimeout, (_, id) => id);
  var saveReject = createEvent({
    name: "saveReject",
    sid: "-64swis"
  }); // eslint-disable-next-line @typescript-eslint/no-empty-function

  var $rejecter = createStore(() => {}, {
    name: "$rejecter",
    sid: "eabbu7"
  }).on(saveReject, (_, rj) => rj);
  var timeoutFx = createEffect(timeout => {
    return new Promise((resolve, reject) => {
      var timeoutId = setTimeout(resolve, timeout);
      saveTimeout(timeoutId);
      saveReject(reject);
    });
  }, {
    name: "timeoutFx",
    sid: "7lc3mj"
  });
  var cleanupFx = attach({
    and: {
      source: [$timeoutId, $rejecter],
      effect: _ref2 => {
        var [id, rj] = _ref2;
        rj();
        if (id) clearTimeout(id);
      }
    },
    or: {
      name: "cleanupFx",
      sid: "pcn5jq"
    }
  });
  guard({
    and: [{
      clock: start,
      source: $timeout,
      filter: $notRunning,
      target: timeoutFx
    }],
    or: {
      sid: "-w5qmwp"
    }
  });

  if (leading) {
    guard({
      and: [{
        clock: start,
        filter: $notRunning,
        target: tick
      }],
      or: {
        sid: "-vpslni"
      }
    });
  }

  sample({
    and: [{
      clock: start,
      fn: () => true,
      target: $isRunning
    }],
    or: {
      sid: "-vaed34"
    }
  });
  guard({
    and: [{
      clock: timeoutFx.done,
      source: $timeout,
      filter: $isRunning,
      target: timeoutFx
    }],
    or: {
      sid: "-v73liy"
    }
  });
  sample({
    and: [{
      clock: timeoutFx.done,
      fn: () => {
        /* to be sure, nothing passed to tick */
      },
      target: tick
    }],
    or: {
      sid: "-urpcwu"
    }
  });

  if (stop) {
    if (trailing) {
      sample({
        and: [{
          clock: stop,
          target: tick
        }],
        or: {
          sid: "-uanqf7"
        }
      });
    }

    $isRunning.on(stop, () => false);
    sample({
      and: [{
        clock: stop,
        target: cleanupFx
      }],
      or: {
        sid: "6lcmyy"
      }
    });
  }

  return {
    tick,
    isRunning: $isRunning
  };
}

function toStoreNumber(value) {
  if (is.store(value, {
    sid: "6p7767"
  })) return value;

  if (typeof value === 'number') {
    return createStore(value, {
      and: {
        name: '$timeout'
      },
      sid: "5hw6se"
    });
  }

  throw new TypeError("timeout parameter in interval method should be number or Store. \"".concat(typeof value, "\" was passed"));
}